var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { staticClass: "text-right mt-10", attrs: { cols: "11", lg: "8" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "indigo", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.loadFlow()
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-refresh")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "8" } },
            [_c("config-table", { attrs: { config: _vm.config } })],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "justify-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "8" } },
            [
              _c("task-flow-card", {
                attrs: {
                  duct: _vm.duct,
                  prjName: _vm.prjName,
                  flow: _vm.flow,
                  "template-color": "blue-grey lighten-4"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("tutti-snackbar", { ref: "snackbar" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }