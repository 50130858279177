var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-1" },
    [
      _c("v-card-title", [_vm._v(" Config Parameters ")]),
      _c("v-data-table", {
        attrs: {
          dense: "",
          "hide-default-footer": "",
          headers: [
            { width: "40%", text: "Property", value: "key" },
            { width: "60%", text: "Value", value: "val" }
          ],
          items: _vm.configItems,
          "items-per-page": 100
        },
        scopedSlots: _vm._u([
          {
            key: "item.val",
            fn: function(ref) {
              var item = ref.item
              return [
                item.val === true
                  ? _c("v-icon", { attrs: { color: "success" } }, [
                      _vm._v(" mdi-check-circle-outline ")
                    ])
                  : item.val === false
                  ? _c("v-icon", { attrs: { color: "error" } }, [
                      _vm._v(" mdi-cancel ")
                    ])
                  : _c("b", [_vm._v(_vm._s(item.val))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }