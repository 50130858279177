var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-10" },
    [
      _c("v-card-title", [_vm._v(" Task Flow ")]),
      _c(
        "v-container",
        [
          _c("v-row", [_c("v-col", { attrs: { align: "right" } })], 1),
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6",
              attrs: { align: "center", color: "grey lighten-2", width: "200" }
            },
            [_vm._v(" Start ")]
          ),
          _c("arrow", { attrs: { color: _vm.templateColor, depth: "1" } }),
          _vm.flow
            ? _c(
                "div",
                [
                  _c("recursive-batch", {
                    attrs: {
                      duct: _vm.duct,
                      "parent-params": {
                        prjName: _vm.prjName,
                        templateColor: _vm.templateColor,
                        node: _vm.flow,
                        depth: 1,
                        isLast: true
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("arrow", { attrs: { color: _vm.templateColor, depth: "1" } }),
          _c(
            "v-card",
            {
              staticClass: "mx-auto py-2 text-h6",
              attrs: { align: "center", color: "grey lighten-2", width: "200" }
            },
            [_vm._v(" End ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }