var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      staticClass: "text-center mx-auto pb-0 py-2",
      attrs: { color: "rgba(0,0,0,0)" }
    },
    [
      _c("v-icon", { attrs: { "x-large": "" } }, [
        _vm._v("mdi-arrow-down-bold")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }